import React from 'react'
import { Link } from 'react-router-dom'
import links from '../../links'
import './Footer.css'

import Logo from '../../assets/logo.png'
import Phone from '../../assets/phone.png'

import Vk from './assets/vk.png'
import Ig from './assets/instagram.png'
import Info from './assets/Info.pdf'

export default () => (
    <footer className="footer">
        <div className="footer__inner">
            <Link to="/" className="footer__logo-link">
                <img src={ Logo } className="footer__logo" alt="Ингуд - страхование ОСАГО и КАСКО" />
            </Link>

            {/*<hr className="delimiter" />*/}

            <nav role="navigation" aria-label="Услуги" className="footer__navigation">
                <div className="footer__navigation__block">
                    {
                        links.slice(0, 3).map(({ name, link }) => (
                            <Link key={ `footer_${ link }` } to={ `/strahovanie/${ link }` } className="footer__navigation__link">{ name }</Link>
                        ))
                    }
                </div>
                <div className="footer__navigation__block">
                    {
                        links.slice(3, 5).map(({ name, link }) => (
                            <Link key={ `footer_${ link }` } to={ `/strahovanie/${ link }` } className="footer__navigation__link">{ name }</Link>
                        ))
                    }
                    <Link to={ `/${ links[5].link }` } className="footer__navigation__link">{ links[5].name }</Link>
                </div>
                <div className="footer__navigation__block">
                    {
                        links.slice(6, 9).map(({ name, link }) => (
                            <Link key={ `footer_${ link }` } to={ `/${ link }` } className="footer__navigation__link">{ name }</Link>
                        ))
                    }
                </div>
            </nav>

            <hr className="delimiter--mobile footer--first-delimiter" />

            <nav role="navigation" aria-label="Услуги" className="footer__navigation--mobile">
                <div className="footer__navigation__block">
                    {
                        links.slice(0, 2).map(({ name, link }) => (
                            <Link key={ `footer_${ link }` } to={ `/strahovanie/${ link }` } className="footer__navigation__link">{ name }</Link>
                        ))
                    }
                </div>
                <div className="footer__navigation__block">
                    {
                        links.slice(2, 5).map(({ name, link }) => (
                            <Link key={ `footer_${ link }` } to={ `/strahovanie/${ link }` } className="footer__navigation__link">{ name }</Link>
                        ))
                    }
                </div>
                <div className="footer__navigation__block">
                    {
                        links.slice(5, 8).map(({ name, link }) => (
                            <Link key={ `footer_${ link }` } to={ `/${ link }` } className="footer__navigation__link">{ name }</Link>
                        ))
                    }
                </div>
                <div className="footer__navigation__block">
                    <Link to={ `/${ links[8].link }` } className="footer__navigation__link">{ links[8].name }</Link>
                </div>
            </nav>

            <div className="footer__phone">
                <img src={ Phone } className="header__phone__icon" alt="Ингуд - телефон для справок" />
                <a href="tel:83912688888" className="footer__phone__number">268-88-88</a>
            </div>

            <hr className="delimiter--mobile footer--second-delimiter" />

        </div>

        <p className="footer__copyright">
            <a href="https://vk.com/ingood.group" className="footer__social" target="_blank" rel="nofollow noopener noreferrer">
                <img src={ Vk } className="footer__social__image" alt="Ингуд страхование официальная страница ВКонтакте" />
            </a>
            <a href="https://instagram.com/ingood_osago?igshid=elnmqpm8x2qn" className="footer__social" target="_blank" rel="nofollow noopener noreferrer">
                <img src={ Ig } className="footer__social__image" alt="Ингуд страхование официальная страница Инстаграм" />
            </a>
        </p>

        <p className="footer__info-link"><a href={Info} target="_blank" rel="nofollow noreferrer noopener">Стандарт защиты прав</a></p>

        <p className="footer__copyright">Сделано в <a href="https://clockwork.one?utm_source=ingood" target="_blank" rel="follow noopener noreferrer" className="footer__copyright__link">Clockwork</a></p>

    </footer>
)