import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import ym, { YMInitializer } from 'react-yandex-metrika'
import { createBrowserHistory } from 'history'
import links from '../../links'
import Header from '../Header'
import Footer from '../Footer'
import './App.css'

import ScrollToTop from '../ScrollToTop'
import { Home, Blog, Post, TO } from '../Pages'
import NotFound from '../Pages/NotFound/NotFound'

const history = createBrowserHistory()
history.listen(({ pathname }) => {
    ym('hit', pathname)
})

export default () => (
    <article className="app">
        <YMInitializer accounts={[ 56600854 ]} options={{ webvisor: true }} version="2" />
        <Router history={ history }>
            <ScrollToTop>
                <Header />
                <Switch>
                    <Route exact path="/" component={ Home } />
                    <Route exact path="/to" component={ TO } />
                    <Route exact path="/blog" component={ Blog } />
                    <Route path="/blog/:link" component={ Post } />
                    <Route exact path="/strahovanie" render={ () => <Redirect to="/" /> } />
                    {
                        links.slice(0, 5).map(({ link, page }) => (
                            <Route key={ `route_${ link }` } path={ `/strahovanie/${ link }` } component={ page } />
                        ))
                    }
                    {
                        links.slice(5, 9).map(({ link, page }) => (
                            <Route key={ `route_${ link }` } path={ `/${ link }` } component={ page } />
                        ))
                    }
                    <Route component={ NotFound } />
                </Switch>
                <Footer />
            </ScrollToTop>
        </Router>
    </article>
)
