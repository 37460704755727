import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'

import './Partneram.css'

import _1 from './assets/1.png'
import _2 from './assets/2.png'
import _3 from './assets/3.png'
import _5 from './assets/5.png'
import _6 from './assets/6.png'
import _7 from './assets/7.png'
import _8 from './assets/8.png'
import _9 from './assets/9.png'
import _10 from './assets/10.png'
import _11 from './assets/11.png'

import Logo from './assets/logo.png'

export default () => {

    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("")

    const annotation = useRef(null)
    const submitButton = useRef(null)

    const onSubmit = (e) => {
        e.preventDefault()

        if (name.length > 0 && phone.length > 0) {
            fetch("https://emalion.clockwork.one/send?app=ingood", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ form: "modal", name, phone , page: "Партнерам"})
            }).then((res) => {
                if(res.ok)
                    return res.json()
                throw Error(`Error with status: ${ res.statusText }`)
            }).then(({ http_code }) => {
                if(http_code === 200) {
                    annotation.current.classList.add("feedback__annotation--success")
                } else {
                    submitButton.current.disabled = false
                    annotation.current.classList.add("feedback__annotation--error")
                    annotation.current.innerText = "При отправке заявки произошла ошибка! Пожалуйста, попробуйте позже."
                }
            }).catch((err) => {
                console.error(err)
                submitButton.current.disabled = false
                annotation.current.classList.add("feedback__annotation--error")
                annotation.current.innerText = "При отправке заявки произошла ошибка! Пожалуйста, попробуйте позже."
            })
        }
    }

    return (
        <main className="content">

            <Helmet>
                <title>Партнерам | Страховой брокер «Ингуд»</title>
            </Helmet>

            <section className="osago__banner">
                <div className="osago__banner__description">
                    <div className="osago__banner__description__content">
                        <h1 className="osago__banner__title">Приглашаем к сотрудничеству партнеров.<br />Агентов, брокеров, автодилеров и пункты ТО</h1>
                    </div>
                    {/*<button className="button button--red mauto">Оставить заявку</button>*/}
                </div>
                <div className="partneram__banner__background" />
            </section>

            <section className="osago__block">
                <div className="block">
                    <h1 className="home__subtitle">Наши актуальные партнеры</h1>
                    <div className="partners">
                        {
                            partners.map(({ name, image }, i) => (
                                <img key={ `partner_${ i }` } src={ image } alt={ name } className="partners__image" />
                            ))
                        }
                    </div>
                </div>
            </section>

            <hr className="delimiter" />

            <section className="osago__block">
                <div className="block">
                    <h1 className="home__subtitle">Для сотрудничества мы предлагаем ряд уникальных условий, которые с первых дней сотрудничества принесут существенную выгоду:</h1>
                    <div className="advs">
                        {
                            adv.map(({ name, image }, i) => (
                                <figure key={ `adv_${ i }` } className="adv">
                                    <img src={ image } alt={ name } className="adv__image" />
                                    <figcaption className="adv__caption">{ name }</figcaption>
                                </figure>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="osago__block flex--custom">
                <div className="block__half">
                    <img src={ Logo } alt="Ингуд - страхование ОСАГО и КАСКО" className="block__half__image" />
                </div>
                <div className="block__half block__half--green">
                    <h2 className="block__half__title">Мы уже более 10 лет предлагаем лучшее, что есть на страховом рынке</h2>
                    <div className="block__half__numbers">
                        <div className="block__half__numbers__wrap">
                            <p className="block__half__numbers__number">8</p>
                            <p className="block__half__numbers__text">филиалов в Красноярсе и Красноярском крае</p>
                        </div>
                        <div className="block__half__numbers__wrap">
                            <p className="block__half__numbers__number">20</p>
                            <p className="block__half__numbers__text">и более партнерских сетей по России</p>
                        </div>
                    </div>
                    <h3 className="block__half__title">Мы готовы организовать бизнес процессы в зависимости от потребностей вашей организации.</h3>
                    <p className="block__half__sign">С уважением, руководитель СА Ингуд Корнаухов А.В.</p>
                </div>
            </section>

            <section className="osago__block home__feedback block">
                <div className="home__feedback__image" />
                <div className="home__feedback__form">
                    <h2 className="home__feedback__title">Работайте с нами</h2>
                    <p className="home__feedback__subtitle">Оставьте заявку онлайн и мы с вами свяжемся в ближайщее время</p>
                    <form onSubmit={ onSubmit }>
                        <input type="text" name="name" placeholder="Имя" value={ name } onChange={ ({ target: { value } }) => setName(value) } />
                        <input type="text" name="phone" placeholder="Телефон" value={ phone } onChange={ ({ target: { value } }) => setPhone(value) } />
                        <button ref={ submitButton } type="submit" className="button button--green button--feedback">Отправить</button>
                    </form>
                    <p ref={ annotation } className="feedback__annotation">Ваша заявка успешно отправлена!</p>
                </div>
            </section>

        </main>
    )
}

const partners = [
    {
        name: 'Макс Страхование | Ингуд страхование',
        image: _1
    },
    {
        name: 'Рeсо Гарантия | Ингуд страхование',
        image: _2
    },
    {
        name: 'Астро-Волга | Ингуд страхование',
        image: _3
    },
    {
        name: 'Альфа Страхование | Ингуд страхование',
        image: _5
    },
    {
        name: 'Согласие Страхование | Ингуд страхование',
        image: _6
    },
    {
        name: 'Югория Страховая Компания | Ингуд страхование',
        image: _11
    }
]

const adv = [
    {
        name: 'Оперативное оформление договоров',
        image: _7
    },
    {
        name: 'Курирование в ходе работы и обратная связь',
        image: _8
    },
    {
        name: 'Комиссия за оформленные договора',
        image: _9
    },
    {
        name: 'Доступы к оформлению ДК',
        image: _10
    }
]