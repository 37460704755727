import React from 'react'
import ReactDOM from 'react-dom'
import WebFont from 'webfontloader'
import smoothscroll from 'smoothscroll-polyfill'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'

smoothscroll.polyfill()

WebFont.load({
    custom: {
        families: [ 'CeraRoundPro' ]
    }
})


ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
