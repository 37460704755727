import React from 'react'
import './Kvartira.css'

import MarkGrey from '../../../assets/mark-grey.png'
import MarkRed from './assets/mark.png'
import Feedback from "../../Feedback/Feedback";
import { Helmet } from "react-helmet"

export default () => (
    <main className="content">

        <Helmet>
            <title>Страхование недвижимости | Страховой брокер «Ингуд»</title>
        </Helmet>

        <section className="kvartira__banner">
            <div className="kvartira__banner__description">
                <div className="kvartira__banner__description__content">
                    <h1 className="kvartira__banner__title">Страховой полис сделает ваш дом настоящей крепостью</h1>
                </div>
            </div>
            <div className="kvartira__banner__background" />
        </section>

        <section className="kvartira__block--grey">
            <div className="osago__inner">
                <h1 className="osago__block__title--black">Что можно застраховать:</h1>
                <div className="osago__columns mt-25">
                    <div className="osago__column--225">
                        <img className="osago__column__mark" src={ MarkGrey } alt="Различные конструкции (Стены, перегородки, балконные конструкции)" />
                        <p className="osago__column__text">Различные конструкции (Стены, перегородки, балконные конструкции)</p>
                    </div>
                    <div className="osago__column--225">
                        <img className="osago__column__mark" src={ MarkGrey } alt="Движимое имущество (мебель, бытовая техника, одежда и т.д)" />
                        <p className="osago__column__text">Движимое имущество (мебель, бытовая техника, одежда и т.д)</p>
                    </div>
                    <div className="osago__column--225">
                        <img className="osago__column__mark" src={ MarkGrey } alt="Инженерное оборудование (водоснабжение, канализация, электрические системы и т.д)" />
                        <p className="osago__column__text">Инженерное оборудование (водоснабжение, канализация, электрические системы и т.д)</p>
                    </div>
                    <div className="osago__column--225 force-bottom-border">
                        <img className="osago__column__mark" src={ MarkGrey } alt="Отделку (обои, покрытие стен и потолка, пола и т.д)" />
                        <p className="osago__column__text">Отделку (обои, покрытие стен и потолка, пола и т.д)</p>
                    </div>
                </div>
                <hr className="delimiter not-mobile" />
                <div className="osago__columns mt-25--not-mobile">
                    <div className="osago__column--full">
                        <img className="osago__column__mark" src={ MarkGrey } alt="Гражданскую ответственность (при нанесении какого-либо ущерба третьим лицам в процессе пользования вами квартирой)" />
                        <p className="osago__column__text">Гражданскую ответственность (при нанесении какого-либо ущерба третьим лицам в процессе пользования вами квартирой)</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="content__block osago__block">
            <div className="osago__inner--59">
                <h1 className="osago__block__title--black">Какие документы нужны для оформления?</h1>

                <ul className="kvartira__list">
                    <li><img src={ MarkRed } alt="Паспорт" /> Паспорт</li>
                    <li><img src={ MarkRed } alt="Заявление" /> Заявление</li>
                    <li><img src={ MarkRed } alt="Результаты оценки и осмотра имущества" /> Результаты оценки и осмотра имущества</li>
                    <li><img src={ MarkRed } alt="Правоустанавливающие документы" /> Правоустанавливающие документы</li>
                </ul>

                <hr className="delimiter" />

                <div className="osago__inner--59 mt-25">
                    <h2 className="osago__block__title--black">Зачем нужно страхование жилого помещения?</h2>
                    <p className="kvartira__text">Добровольное страхование квартиры защитит ваше движимое и недвижимое имущество в случае каких-либо повреждений и непредвиденных обстоятельств: ваш дом или квартиру могут ограбить, затопить соседи, может случиться пожар или любое другое стихийное бедствие. Страховой полис предусматривает денежную выплату, ее вы можете потратить на покупку испорченного имущества, ремонт и восстановительные работы.</p>
                </div>
            </div>
        </section>

        <section className="kvartira__block--red">
            <h1 className="kvartira__block__title--white">Что делать, если произошел страховой случай?</h1>
            <div className="kvartira__columns block">
                <div className="kvartira__column">
                    <span className="kvartira__column__number">1</span>
                    <p className="osago__column__text">Вызовите сотрудника полиции/пожарных/представителей ТСЖ в зависимости от происшествия</p>
                </div>
                <div className="kvartira__column">
                    <span className="kvartira__column__number">2</span>
                    <p className="osago__column__text">Примите экстренные меры, которые помогут уменьшить или как-либо исправить ситуацию</p>
                </div>
                <div className="kvartira__column">
                    <span className="kvartira__column__number">3</span>
                    <p className="osago__column__text">В течение 3 дней сообщите о наступлении страхового случая и соберите соответствующие документы</p>
                </div>
            </div>
        </section>

        <section className="content__block osago__block">
            <h1 className="osago__block__title--black">Необходимые документы</h1>
            <div className="osago__inner--59 mt-25">
                <ul className="kvartira__simple-list">
                    <li>заявление с подробным описанием случившегося и повреждений;</li>
                    <li>полис о страховании жилого помещения;</li>
                    <li>паспорт;</li>
                    <li>правоустанавливающие документы;</li>
                    <li>документ из организации, который установил дату и причину события, повлекшего страховой случай;</li>
                    <li>банковские реквизиты для получения выплаты.</li>
                </ul>
            </div>
        </section>

        <section className="osago__block">
            <div className="osago__inner--59 mt-10">
                <h2 className="puteshestviya__title--blue">Вопросы и ответы</h2>
                <div className="puteshestviya__block">
                    <h3 className="puteshestviya__block__title">Сколько ждать финансовую выплату?</h3>
                    <p className="puteshestviya__block__text">Если с документами все в порядке, то оплата придет в течение двух рабочих недель.</p>
                    <hr className="delimiter--puteshestviya" />
                </div>

                <div className="puteshestviya__block">
                    <h3 className="puteshestviya__block__title">Я снимаю жилье в аренду, могу ли я его застраховать?</h3>
                    <p className="puteshestviya__block__text">Да, если в договоре аренды указана ваша обязанность по восстановлению поврежденного имущества, проведению ремонта и т.д.</p>
                    <hr className="delimiter--puteshestviya" />
                </div>

                <div className="puteshestviya__block puteshestviya__block--center">
                    <Feedback className="button button--red mauto" page="Квартира" />
                </div>
            </div>
        </section>

    </main>
)
