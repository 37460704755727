import React from 'react'
import './Kasko.css'
import MarkGrey from "../../../assets/mark-grey.png"
import MarkRed from "../Kvartira/assets/mark.png"
import Feedback from "../../Feedback/Feedback"
import { Helmet } from 'react-helmet'

export default () => (
    <main className="content kasko">

        <Helmet>
            <title>КАСКО | Страховой брокер «Ингуд»</title>
        </Helmet>

        <section className="kvartira__banner">
            <div className="kvartira__banner__description">
                <div className="kvartira__banner__description__content">
                    <h1 className="kvartira__banner__title">КАСКО - это самая надежная защита вашего имущества</h1>
                </div>
            </div>
            <div className="kasko__banner__background" />
        </section>

        <section className="kvartira__block--grey">
            <div className="osago__inner">
                <h1 className="osago__block__title--black">Полис КАСКО оградит не только от финансовых потерь при ДТП. Его действие распространяется в случае:</h1>
                <div className="osago__columns--center mt-25">
                    <div className="osago__column--30">
                        <img className="osago__column__mark" src={ MarkGrey } alt="если вам случайно или умышленно поцарапали автомобиль (даже если виновник скрылся)" />
                        <p className="osago__column__text">если вам случайно или умышленно поцарапали автомобиль (даже если виновник скрылся)</p>
                    </div>
                    <div className="osago__column--30">
                        <img className="osago__column__mark" src={ MarkGrey } alt="если случился пожар по какому-либо поводу (поджог, самовозгорание, короткое замыкание)" />
                        <p className="osago__column__text">если случился пожар по какому-либо поводу (поджог, самовозгорание, короткое замыкание)</p>
                    </div>
                    <div className="osago__column--30 force-bottom-border">
                        <img className="osago__column__mark" src={ MarkGrey } alt="если что-то упало на автомобиль и повредило его кузов. Это может быть что угодно - от сосульки до строительных отходов" />
                        <p className="osago__column__text">если что-то упало на автомобиль и повредило его кузов. Это может быть что угодно - от сосульки до строительных отходов</p>
                    </div>
                </div>
                <hr className="delimiter not-mobile" />
                <div className="osago__columns--center mt-25--not-mobile">
                    <div className="osago__column--30">
                        <img className="osago__column__mark" src={ MarkGrey } alt="если произошло стихийное бедствие (последствия наводнения, урагана и т.д) или автомобиль упал в воду" />
                        <p className="osago__column__text">если произошло стихийное бедствие (последствия наводнения, урагана и т.д) или автомобиль упал в воду</p>
                    </div>
                    <div className="osago__column--30">
                        <img className="osago__column__mark" src={ MarkGrey } alt="если автомобиль повредило животное" />
                        <p className="osago__column__text">если автомобиль повредило животное</p>
                    </div>
                    <div className="osago__column--30">
                        <img className="osago__column__mark" src={ MarkGrey } alt="если автомобиль попал в ямы при просадке грунта, выброса гравия из под колес другого транспортного средства, при провале дорожного полотна" />
                        <p className="osago__column__text">если автомобиль попал в ямы при просадке грунта, выброса гравия из под колес другого транспортного средства, при провале дорожного полотна</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="osago__block block">
            <h1 className="kasko__block__title">5 причины оформить полис КАСКО</h1>
            <div className="kasko__columns">
                <div className="kasko__column">
                    <span className="kasko__column__number">1</span>
                    <p className="osago__column__text">Это рентабельно. Ваши вложения в полис окупят затраты на ремонт автомобиля в будущем.</p>
                </div>
                <div className="kasko__column">
                    <span className="kasko__column__number">2</span>
                    <p className="osago__column__text">Это максимально безопасно. Полис защищает ваш автомобиль от непредвиденных обстоятельств.</p>
                </div>
                <div className="kasko__column">
                    <span className="kasko__column__number">3</span>
                    <p className="osago__column__text">Это защищает. Многие участники дорожного движения не имеют полис ОСАГО, и в случае аварии по вине незастрахованного водителя вы не сможете получить возмещение от страховой компании и самого виновника ДТП.</p>
                </div>
                <div className="kasko__column">
                    <span className="kasko__column__number">4</span>
                    <p className="osago__column__text">Это предусмотрительно. Автомобиль могут угнать/поджечь вандалы, а с полисом вы можете быть уверены, что вам возместят ущерб.</p>
                </div>
                <div className="kasko__column">
                    <span className="kasko__column__number">5</span>
                    <p className="osago__column__text">Это вселяет уверенность. Как на дороге, так и в повседневной жизни.</p>
                </div>
            </div>
        </section>

        <hr className="delimiter" />

        <section className="content__block osago__block">
            <div className="osago__inner--59">
                <h2 className="osago__block__title--black mt-10">Какие документы нужны для заключения договора?</h2>

                <ul className="kvartira__list">
                    <li><img src={ MarkRed } alt="Заявление" /> Заявление</li>
                    <li><img src={ MarkRed } alt="Регистрационные документы на автомобиль" /> Регистрационные документы на автомобиль</li>
                    <li><img src={ MarkRed } alt="Паспорт или другое удостоверение личности страхователя" /> Паспорт или другое удостоверение личности страхователя</li>
                    <li><img src={ MarkRed } alt="Копии водительских удостоверений всех водителей, которые будут в будущем управлять автомобилем и будут вписаны в полис" /> Копии водительских удостоверений всех водителей, которые будут в будущем управлять автомобилем и будут вписаны в полис</li>
                    <li><img src={ MarkRed } alt="Нотариальная доверенность, если полис оформляет доверенное лицо" /> Нотариальная доверенность, если полис оформляет доверенное лицо</li>
                    <li><img src={ MarkRed } alt="Технический паспорт автомобиля" /> Технический паспорт автомобиля</li>
                </ul>
            </div>
        </section>

        <section className="osago__block">
            <div className="osago__inner--59 mt-10--not-mobile">
                <div className="puteshestviya__block puteshestviya__block--center">
                    <Feedback className="button button--red mauto" page="КАСКО" />
                </div>
            </div>
        </section>

    </main>
)