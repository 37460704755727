import {
    Osago, Kasko, Puteshestviya,
    Kvartira, Zdorove, Partneram,
    Voprosy, Politika, Novosti, Blog
} from './components/Pages'

export default [
    {
        name: 'ОСАГО',
        link: 'osago',
        page: Osago
    },
    {
        name: 'КАСКО',
        link: 'kasko',
        page: Kasko
    },
    {
        name: 'Путешествия',
        link: 'puteshestviya',
        page: Puteshestviya
    },
    {
        name: 'Квартира и Дом',
        link: 'kvartira-i-dom',
        page: Kvartira
    },
    {
        name: 'Здоровье',
        link: 'zdorove',
        page: Zdorove
    },
    {
        name: 'Партнерам',
        link: 'partneram',
        page: Partneram
    },
    {
        name: 'Вопросы и Ответы',
        link: 'voprosy-i-otvety',
        page: Voprosy
    },
    {
        name: 'Новости',
        link: 'novosti',
        page: Novosti
    },
    {
        name: 'Политика конфиденциальности',
        link: 'politika-konfidencialnosti',
        page: Politika
    }
]