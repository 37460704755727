import React from 'react'
import './Puteshestviya.css'

import Feedback from '../../Feedback/Feedback'
import { Helmet } from 'react-helmet'

export default () => {

    return (
        <main className="content">

            <Helmet>
                <title>Страхование путешествий | Страховой брокер «Ингуд»</title>
            </Helmet>

            <section className="puteshestviya__banner">
                <div className="puteshestviya__banner__description">
                    <div className="puteshestviya__banner__description__content">
                        <h1 className="puteshestviya__banner__title">Чувствуйте себя в путешествии так же уверенно, как и дома</h1>
                    </div>
                </div>
                <div className="puteshestviya__banner__background" />
            </section>

            <section className="osago__block block">
                <h1 className="puteshestviya__title">На что обратить внимание при выборе полиса страхования путешественников:</h1>
                <div className="osago__columns">
                    {
                        points.map(({ number, name, content }) => (
                            <div key={ `points_${ number }` } className="osago__column">
                                <span className="puteshestviya__column__number">{ number }</span>
                                <h3 className="puteshestviya__column__title">{ name }</h3>
                                <p className="osago__column__text">{ content }</p>
                            </div>
                        ))
                    }
                </div>
            </section>

            <section className="osago__block">
                <div className="osago__inner--59">
                    <h2 className="puteshestviya__title--blue">Вопросы и ответы</h2>
                    {
                        qa.map(({ name, content }, i) => (
                            <div key={ `qa_${ i }` } className="puteshestviya__block">
                                <h3 className="puteshestviya__block__title">{ name }</h3>
                                <p className="puteshestviya__block__text">{ content }</p>
                                <hr className="delimiter--puteshestviya" />
                            </div>
                        ))
                    }

                    <div className="puteshestviya__block puteshestviya__block--center">
                        <Feedback className="button button--blue mauto" page="Путешествия" />
                    </div>
                </div>
            </section>

        </main>
    )

}

const points = [
    {
        number: 1,
        name: "Вид отдыха",
        content: "Он будет активным или спокойным? В случае активного отдыха (дайвинг, скалолазание, вождение мотоцикла и т.д) риск получить травму возрастает в разы."
    },
    {
        number: 2,
        name: "Частота путешествий",
        content: "При регулярных поездках выгоднее оформить полис на длительный период."
    },
    {
        number: 3,
        name: "Сумма страхового покрытия",
        content: "Для каждой страны она строго регламентирована и может отличаться."
    },
    {
        number: 4,
        name: "Необходимость страхования при дополнительных рисках",
        content: "В случае утери багажа, невыезда из страны и т.д."
    }
]

const qa = [
    {
        name: 'Зачем нужен туристический страховой полис?',
        content: 'Страховой полис путешественника гарантирует медицинскую помощь в случае болезни, несчастного случая или экстренного хирургического вмешательства за пределами вашего места жительства. Все расходы понесет страховая компания.'
    },
    {
        name: 'При путешествии заграницу страховка обязательна?',
        content: 'Страховой полис - один из обязательных документов при оформлении, например, Шенгенской визы. Также при его отсутствии вас могут не пустить в страну.'
    },
    {
        name: 'Нужен ли туристический полис в путешествии по России?',
        content: 'Страховой случай может наступить не только при выезде зарубеж, но и в родной стране. Мы рекомендуем оформлять полис и в том, и в другом случае.'
    },
    {
        name: 'Обязан ли я носить полис с собой?',
        content: 'Это не обязательно, но желательно. Для удобства сохраните все данные в телефоне, сфотографируйте полис и запишите данные контактного центра.'
    }
]