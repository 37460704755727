import React from 'react'
import './TO.css'
import MarkRed from "../Kvartira/assets/mark.png"
import Feedback from "../../Feedback/Feedback"
import { Helmet } from 'react-helmet'

import To_doc from '../../../assets/to.doc'
import _273 from '../../../assets/273.doc'
import _573 from '../../../assets/573.doc'
import _1054 from "../../../assets/1054.pdf"
import _1434 from "../../../assets/1434.docx"
import _170 from "../../../assets/170.docx"

export default () => (
    <main className="content kasko">

        <Helmet>
            <title>Технический осмотр | Страховой брокер «Ингуд»</title>
        </Helmet>

        <section className="osago__banner">
            <div className="osago__banner__description">
                <div className="osago__banner__description__content">
                    <h1 className="osago__banner__title">Технический осмотр автомобиля</h1>
                </div>
                <Feedback className="button button--red mauto" page="Технический осмотр" />
            </div>
            <div className="to__banner__background" />
        </section>

        <section className="kvartira__block--grey">
            <div className="osago__inner">
                <h1 className="osago__block__title--black">Информация о компании</h1>
                <div className="to__block">
                    <div>
                        <p>ООО «ОДП»</p>
                        <p>ИНН 2465346453, КПП 246501001</p>
                        <p>ОГРН 1222400009952</p>
                        <p>Юр. адрес: г. Красноярск, пр. Молодежный, д. 7, кв. 198</p>
                        <br/>
                        <p>р/сч. 40702810701500126203</p>
                        <p>ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ", г. Москва</p>
                        <p>к/сч. 30101810845250000999</p>
                        <p>БИК 044525999</p>
                        <br />
                        <p>тел. +7 (950) 435-09-99</p>
                        <p>2711114@gmail.com</p>
                    </div>
                    <div>
                        <p>Время работы: Пн-Вс с 9:00 до 18:00</p>
                        <p>Адрес оператора ТО: г. Ачинск, ул. Дзержинского, д. 26г</p>
                        <p>Адрес пункта ТО: г. Ачинск, ул. Дзержинского, д. 26г</p>
                    </div>
                </div>
                <div className="to__block--center">
                    <p>Плата за ТО категория M1 – 913 р., N1 – 999 р.</p>
                    <p>Размер платы за проведение повторного технического осмотра, который определяется объемом выполненных работ, устанавливается оператором технического осмотра и не может превышать предельный размер платы за проведение технического осмотра.</p>
                </div>
            </div>
        </section>

        <section className="osago__block">
            <div className="osago__inner--59 mt-10">
                <h2 className="puteshestviya__title--blue">Законодательство в сфере техосмотра</h2>
                <div className="puteshestviya__block">
                    <a href={ _273 } className="to__block__title">Федеральный закон Российской Федерации от 28 июля 2012 г. N 130-ФЗ "О внесении изменений в отдельные законодательные акты Российской Федерации"</a>
                    <hr className="delimiter--to" />
                </div>

                <div className="puteshestviya__block">
                    <a href={ _573 } className="to__block__title">Приказ Министерства экономического развития Российской Федерации (Минэкономразвития России) от 14 октября 2011 г. N 573 г. Москва "Об утверждении формы типового договора о проведении технического осмотра"</a>
                    <hr className="delimiter--to" />
                </div>

                <div className="puteshestviya__block">
                    <a href={ _1054 } target="_blank" className="to__block__title">Постановление О внесении изменений в Правила проведения технического осмотра транспортных средств</a>
                    <hr className="delimiter--to" />
                </div>

                <div className="puteshestviya__block">
                    <a href={ To_doc } className="to__block__title">Форма типового договора о проведении технического осмотра</a>
                    <hr className="delimiter--to" />
                </div>

                <div className="puteshestviya__block">
                    <a href={ _1434 } className="to__block__title">Об утверждении правил проведения технического осмотра транспортных средств, а также о внесении изменений в некоторые акты правительства российской федерации</a>
                    <hr className="delimiter--to" />
                </div>

                <div className="puteshestviya__block">
                    <a href={ _170 } className="to__block__title">О техническом осмотре транспортных средств и о внесении изменений в отдельные законодательные акты российской федерации</a>
                    <hr className="delimiter--to" />
                </div>
            </div>
        </section>

        <section className="content__block osago__block">
            <div className="osago__inner--59">
                <h2 className="osago__block__title--black mt-10">Физические лица должны иметь следующий пакет документов:</h2>

                <ul className="kvartira__list">
                    <li><img src={ MarkRed } alt="Удостоверение личности или паспорт" /> удостоверение личности или паспорт</li>
                    <li><img src={ MarkRed } alt="Водительские права" /> водительские права</li>
                    <li><img src={ MarkRed } alt="техпаспорт транспортного средства или свидетельство,подтверждающее регистрацию ТС в отделении ГИБДД" /> техпаспорт транспортного средства или свидетельство,подтверждающее регистрацию ТС в отделении ГИБДД</li>
                </ul>
            </div>
        </section>

        <section className="osago__block">
            <div className="osago__inner--59 mt-10--not-mobile">
                <div className="puteshestviya__block puteshestviya__block--center">
                    <Feedback className="button button--red mauto" page="Технический осмотр" />
                </div>
            </div>
        </section>

    </main>
)