import _1 from './assets/blog/cars.png'

const store = [
    {
        link: 'skhema-zayavleniya-ubytka-na-meste-dtp-po-evroprotokolu',
        date: '25 февраля 2020',
        title: 'Схема заявления убытка на месте ДТП по Европротоколу',
        description: 'Европротокол - это процедура оформления документов о ДТП без участия сотрудников полиции.',
        image: _1,
        content: ''
    }
]

export const all = () => {
    return store
}

export const findByLink = (link) => {
    return store.find((obj) => obj.link === link)
}