import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import './NotFound.css'
import Smile from '../Home/assets/smile.jpg'

export default () => (

    <main className="content not-found">

        <Helmet>
            <title>Не найдено | Ингуд страхование</title>
        </Helmet>

        <section className="not-found__block" style={{ backgroundImage: `url(${ Smile })` }}>
            <h1>404</h1>
            <p>
                К сожалению, такой страницы у нас нет, но вы можете попробовать наш <Link to="/strahovanie/osago#osago-calc">Калькулятор ОСАГО</Link>
            </p>
        </section>

    </main>

)