import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { all } from '../../../store'

import './Blog.css'

export default () => {

    const store = all()

    return (
        <main className="content blog">

            <Helmet>
                <title>Блог | Страховой брокер «Ингуд»</title>
                <meta name="description" content="Схема заявления убытка на месте ДТП по Европротоколу" />
            </Helmet>

            <section className="blog__posts">
                {
                    store.map(({ link, image, title, date, description }) => (
                        <Link to={ `/blog/${ link }` } className="blog__post__wrapper" key={ link }>
                            <figure className="blog__post">
                                <img src={ image } className="blog__post__image" alt={ title } />
                                <figcaption className="blog__post__content">
                                    <h2 className="blog__post__title">{ title }</h2>
                                    <small className="blog__post__date">{ date }</small>
                                    <p className="blog__post__description">{ description }</p>
                                </figcaption>
                            </figure>
                        </Link>
                    ))
                }
            </section>

        </main>
    )
}