import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

const ScrollToTop = ({ location: { pathname }, children }) => {

    const [ pathName, setPathName ] = useState(pathname)

    useEffect(() => {
        if(pathname !== pathName) {
            window.scrollTo(0, 0)
            setPathName(pathname)
        }
    }, [pathName, pathname])

    // componentDidUpdate(prevProps) {
    //     if (this.props.location.pathname !== prevProps.location.pathname)
    //         window.scrollTo(0, 0)
    // }

    return children
}

export default withRouter(ScrollToTop)
