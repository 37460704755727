import React from 'react'
import './Zdorove.css'

import Backpack from './assets/backpack.png'
import Forest from './assets/forest.png'
import Feedback from "../../Feedback/Feedback"
import { Helmet } from 'react-helmet'

export default () => {

    return (
        <main className="content zdorove">

            <Helmet>
                <title>Страхование здоровья | Страховой брокер «Ингуд»</title>
            </Helmet>

            <section className="osago__banner">
                <div className="zdorove__banner__description">
                    <div className="zdorove__banner__description__content">
                        <h1 className="zdorove__banner__title">Будьте уверены в завтрашнем дне: страхование от несчастных случаев для вас и ваших близких</h1>
                    </div>
                </div>
                <div className="zdorove__banner__background" />
            </section>

            <section className="osago__block block">
                <h1 className="zdorove__title">5 аргументов «за» страхование<br/>от несчастных случаев:</h1>
                <div className="zdorove__columns">
                    <div className="zdorove__column ">
                        <span className="puteshestviya__column__number">1</span>
                        <p className="osago__column__text">это поможет компенсировать затраты на здоровье без вреда для семейного бюджета</p>
                    </div>
                    <div className="zdorove__column">
                        <span className="puteshestviya__column__number">2</span>
                        <p className="osago__column__text">вам придется меньше тревожиться за ваше здоровье и здоровье ваших близких</p>
                    </div>
                    <div className="zdorove__column">
                        <span className="puteshestviya__column__number">3</span>
                        <p className="osago__column__text">вы сможете не бояться, что при страховом случае ваши близкие испытают трудности с оплатой медицинских счетов</p>
                    </div>
                    <div className="zdorove__column">
                        <span className="puteshestviya__column__number">4</span>
                        <p className="osago__column__text">родители могут быть уверены, что здоровье их детей в безопасности, даже если они далеко</p>
                    </div>
                    <div className="zdorove__column">
                        <span className="puteshestviya__column__number">5</span>
                        <p className="osago__column__text">организации смогут повысить доверие и лояльность своих сотрудников</p>
                    </div>
                </div>
            </section>

            <section className="osago__block--grey">
                <h2 className="osago__block__title--black">Для кого особенно актуально</h2>
                <div className="zdorove__columns block">
                    <div className="zdorove__column--row">
                        <img src={ Forest } alt="Для каждого жителя и гостя таежной Сибири от Улан-Удэ до Омска" />
                        <p className="osago__column__text">Для каждого жителя и гостя таежной Сибири от Улан-Удэ до Омска</p>
                    </div>
                    <div className="zdorove__column--row">
                        <img src={ Backpack } alt="Для всех любителей отдыха на природе, дачников, путешественников и людей, ведущих активный образ жизни" />
                        <p className="osago__column__text">Для всех любителей отдыха на природе, дачников, путешественников и людей, ведущих активный образ жизни</p>
                    </div>
                </div>
            </section>

            <section className="osago__block mt-10">
                <div className="osago__inner--59">
                    <h2 className="puteshestviya__title--blue">Страхование от несчастных случаев</h2>
                    <div className="puteshestviya__block">
                        <h3 className="puteshestviya__block__title">Кому необходима страховая защита от несчастного случая?</h3>
                        <ul className="puteshestviya__block__text">
                            <li>Каждому, кто боиться потерять источник дохода в случае наступления страхового случая;</li>
                            <li>Кормильцам семьи (возможна пожизненная пенсия для супруга в случае смерти застрахованного);</li>
                            <li>Специалистам, профессия которых связана с повышенным риском для жизни;</li>
                            <li>Спортсменам;</li>
                            <li>Детям;</li>
                            <li>Путешественникам;</li>
                            <li>Людям пожилого возраста;</li>
                            <li>Семьям (круглый год или посезонно);</li>
                            <li>Организациям для защиты здоровья сотрудников.</li>
                        </ul>
                        <hr className="delimiter--puteshestviya" />
                    </div>

                    <div className="puteshestviya__block">
                        <h3 className="puteshestviya__block__title">Что может быть застраховано по этому полису?</h3>
                        <div className="puteshestviya__block__text" style={{ marginBottom: '1rem' }}>
                            <p>Ваш полис может включать все последствия несчастного случая:</p>
                            <ul>
                                <li>травмы различной тяжести;</li>
                                <li>утрата трудоспособности на неопределенное время;</li>
                                <li>инвалидность;</li>
                                <li>смерть.</li>
                            </ul>
                        </div>
                        <hr className="delimiter--puteshestviya" />
                    </div>

                    <div className="puteshestviya__block puteshestviya__block--center">
                        <Feedback className="button button--blue mauto" page="Здоровье" />
                    </div>
                </div>
            </section>

        </main>
    )

}