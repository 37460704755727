import React, { Fragment } from 'react'
import { YMaps, Map, GeoObject, ZoomControl } from 'react-yandex-maps'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'
import './Home.css'

import Mark from '../../../assets/mark.png'
import Calendar from './assets/calendar.png'
import Delivery from './assets/delivery.png'
import Hands from './assets/hands.png'
import Payments from './assets/payments.png'
// import Feedback from '../../Feedback/Feedback'

import _1 from '../Partneram/assets/1.png'
import _2 from '../Partneram/assets/2.png'
import _3 from '../Partneram/assets/3.png'
import _5 from '../Partneram/assets/5.png'
import _6 from '../Partneram/assets/6.png'
import _11 from '../Partneram/assets/11.png'
import Back from "./assets/iRnUeA04kUY.png"

export default () => {

    const onQAClick = (e) => {
        const content = e.currentTarget.nextElementSibling
        const icon = e.currentTarget.lastChild

        icon.classList.toggle('active')

        if(content.style.display === 'block')
            content.style.display = 'none'
        else
            content.style.display = 'block'
    }

    return (
        <main className="content home">

            <Helmet>
                <title>Страховой брокер «Ингуд»</title>
            </Helmet>

            <section className="osago__banner">
                <div className="osago__banner__description">
                    <div className="osago__banner__description__content">
                        <h1 className="osago__banner__title">Оформить полис ОСАГО</h1>
                        <ul className="osago__banner__list">
                            <li><img src={ Mark } alt="Страхование ОСАГО Ингуд" /> без дополнительных страховок</li>
                            <li><img src={ Mark } alt="Страхование ОСАГО Ингуд" /> бесплатная доставка по красноярску</li>
                            <li><img src={ Mark } alt="Страхование ОСАГО Ингуд" /> каждому - набор автомобилиста</li>
                        </ul>
                    </div>
                    <Link to="/strahovanie/osago#osago-calc" className="button button--red">Оформить ОСАГО</Link>
                </div>
                <div className="osago__banner__background" style={{ backgroundImage: `url(${ Back })` }} />
            </section>

            <section className="home__advantages block">
                {
                    advantages.map(({ image, text }, i) => (
                        <figure key={ `adv_${ i }` } className="advantage">
                            <img src={ image } alt={ text } />
                            <p>{ text }</p>
                        </figure>
                    ))
                }
            </section>

            <section className="osago__block--grey">
                <div className="block">
                    <h1 className="home__subtitle">Страховые компании</h1>
                    <div className="partners">
                        {
                            partners.map(({ name, image }, i) => (
                                <img key={ `partner_${ i }` } src={ image } alt={ name } className="partners__image" />
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="osago__block block">
                <h1 className="puteshestviya__title--blue">Вопросы и ответы</h1>
                <div className="qa">
                    {
                        qa.map(({ name, content }, i) => (
                            <Fragment key={ `qa_${ i }` }>
                                <div className="qa__row" onClick={ onQAClick }>
                                    <p dangerouslySetInnerHTML={{ __html: name }} />
                                    <div className="plus" />
                                </div>
                                <div className="qa__content">
                                    <p dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            </Fragment>
                        ))
                    }
                </div>
            </section>

            <section className="osago__block home__feedback block">
                <div className="home__feedback__image" />
                <div className="home__feedback__form">
                    <h2 className="home__feedback__title">Есть вопрос?</h2>
                    <p className="home__feedback__subtitle">Напишите нам и наш менеджер свяжется с Вами</p>
                    <form onSubmit={ () => {} }>
                        <input type="text" name="name" placeholder="Имя" onChange={ ({ target: { value } }) => {} } />
                        <input type="text" name="contact" placeholder="Телефон или E-mail" onChange={ ({ target: { value } }) => {} } />
                        <textarea rows={ 3 } name="message" placeholder="Введите Ваше сообщение" onChange={ ({ target: { value } }) => {} } />
                        <button type="submit" className="button button--green button--feedback">Отправить</button>
                    </form>
                </div>
            </section>

            <YMaps>
                <Map
                    instanceRef={ ref => { ref && ref.behaviors.disable('scrollZoom') }}
                    defaultState={{ center: [56.034266, 92.923043], zoom: 12 }}
                    className="home__map">
                    {
                        branches.map(({ coords, name }, i) => (
                            <GeoObject
                                key={ `branch_${ i }` }
                                geometry={{
                                    type: 'Point',
                                    coordinates: coords
                                }}
                                properties={{
                                    iconCaption: name
                                }}
                            />
                        ))
                    }

                    <ZoomControl options={{ float: 'right' }} />

                </Map>
            </YMaps>

            <div className="creds">
                Номер счёта: 40802810823560001118<br/>
                Валюта: RUR<br/>
                Название: Корнаухов Андрей Викторович (ИП)<br/>
                ИНН: 246517633555<br/>
                Банк: ФИЛИАЛ "НОВОСИБИРСКИЙ" АО "АЛЬФА-БАНК"<br/>
                БИК: 045004774<br/>
                Кор. счёт: 30101810600000000774<br/>
                Адрес получателя: проспект Молодежный, д. 7, Красноярский край, г. Красноярск
            </div>

        </main>
    )
}

const advantages = [
    {
        image: Calendar,
        text: 'работаем без выходных'
    },
    {
        image: Delivery,
        text: 'бесплатная доставка'
    },
    {
        image: Hands,
        text: 'более 10 страховых компаний'
    },
    {
        image: Payments,
        text: 'наличный и безналичный расчет'
    }
]

const qa = [
    {
        name: 'Какие документы нужны для оформления полиса <strong>ОСАГО</strong>?',
        content: 'Классический перечень документов для ОСАГО - это паспорт владельца, свидетельство о регистрации автомобиля (СТС), водительские права (а также копии и оригиналы ВУ для тех водителей, кто будет вписан в полис).'
    },
    {
        name: 'Как можно проверить подлинность полиса ОСАГО?',
        content: 'Проверить свой полис ОСАГО вы можете на сайте Российского союза автостраховщиков <a href="https://autoins.ru" target="_blank" rel="nofollow noopener noreferrer">autoins.ru</a>'
    }
]

const branches = [
    {
        coords: [56.059519, 92.912849],
        name: "9 мая, 46"
    },
    {
        coords: [56.012798, 92.815184],
        name: "Копылова, 66"
    },
    {
        coords: [56.020929, 92.870233],
        name: "Брянская, 23а"
    },
    {
        coords: [56.065886, 92.981345],
        name: "Металлургов, 1г"
    },
    {
        coords: [56.038408, 92.793606],
        name: "Цимлянская, 35б"
    },
    {
        coords: [56.030779, 92.909525],
        name: "Аэровокзальная, 13"
    },
    {
        coords: [55.988756, 92.986825],
        name: "Спортивная, 60г/1"
    },
    {
        coords: [56.069977, 92.933447],
        name: "Шумяцкого, 8"
    },
    {
        coords: [56.059524, 92.912265],
        name: "9 мая, 46/6"
    }
]

const partners = [
    {
        name: 'Макс ОСАГО | Ингуд страхование',
        image: _1
    },
    {
        name: 'Рeсо Гарантия ОСАГО | Ингуд страхование',
        image: _2
    },
    {
        name: 'Астро-Волга ОСАГО | Ингуд страхование',
        image: _3
    },
    {
        name: 'Альфа Страхование ОСАГО | Ингуд страхование',
        image: _5
    },
    {
        name: 'Согласие Страхование ОСАГО | Ингуд страхование',
        image: _6
    },
    {
        name: 'Югория Страховая Компания ОСАГО | Ингуд страхование',
        image: _11
    }
]