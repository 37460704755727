import React, { useState, useRef, useEffect } from 'react'
import Select from 'react-select'
import Feedback from '../../Feedback/Feedback'
import './Osago.css'

import MarkGrey from '../../../assets/mark-grey.png'
import Support from './assets/support.png'
import Car from './assets/car.png'
import { Helmet } from 'react-helmet'

export default ({ history }) => {

    const [ region, setRegion ] = useState(regionOptions[0])
    const [ power, setPower ] = useState(powerOptions[0])
    const [ period, setPeriod ] = useState(periodOptions[0])
    const [ drivers, setDrivers ] = useState(driversOptions[0])
    const [ kbm, setKbm ] = useState(kbmOptions[0])
    const [ data, setData ] = useState({})
    const [ age, setAge ] = useState(ageOptions[0])
    const [ exp, setExp ] = useState(expOptions[0])

    const res = useRef(null)
    const form = useRef(null)

    useEffect(() => {
        if (history.location.hash === "#osago-calc") {
            const f = document.getElementById("osago-calc")
            f.scrollIntoView({ behavior: "smooth" })
        }
    })

    const calc = () => {
        let min = priceRange.min
        let max = priceRange.max

        const ageExp = ageExpOptions.find((row) => {
            return row.age === age.value && row.exp === exp.value
        })

        const _kbm = kbm.value === 'dontKnow' ? 1 : kbm.value

        if (drivers.value !== "unlimited") {
            min *= (parseFloat(region.value) * parseFloat(power.value) * parseFloat(period.value) * parseFloat(_kbm) * parseFloat(ageExp.value))
            max *= (parseFloat(region.value) * parseFloat(power.value) * parseFloat(period.value) * parseFloat(_kbm) * parseFloat(ageExp.value))
        } else {
            min *= (parseFloat(region.value) * parseFloat(power.value) * parseFloat(period.value) * 1.94)
            max *= (parseFloat(region.value) * parseFloat(power.value) * parseFloat(period.value) * 1.94)
        }

        min = Math.ceil(min)
        max = Math.ceil(max)

        setData({
            region: region.label,
            kbm: kbm.label,
            power: power.label,
            period: period.label,
            age: age.label,
            exp: exp.label
        })

        res.current.innerText = `Страховка для вас стоит от ${ min } до ${ max } рублей.`

        form.current.classList.remove("hidden")
    }

    return (
        <main className="content osago">

            <Helmet>
                <title>Оформить ОСАГО в Красноярске | Страхование «Ингуд»</title>
                <meta
                    name="description"
                    content="Страховая компания «Ингуд» предлагает услуги по оформлению страховых полюсов ОСАГО. Онлан-калькулятор поможет вам рассчитать стоимость вашей будущей страховки." />
            </Helmet>

            <section className="osago__banner">
                <div className="osago__banner__description">
                    <div className="osago__banner__description__content">
                        <h1 className="osago__banner__title">Оформите полис обязательного автострахования без каких-либо дополнительных страховок</h1>
                    </div>
                    <Feedback className="button button--red mauto" page="ОСАГО" />
                </div>
                <div className="osago__banner__background" />
            </section>

            <span id="osago-calc" />

            {
                window.innerWidth >= 760 ? (
                    <iframe
                        src="https://sed-24.ru/widget/agent/6972/"
                        style={{width:"100%", height:"100vh", border:"0px"}} name="sedwidget" id="sedwidget" />
                ) : null
            }

            <section className="content__block osago__block block">
                <h1 className="osago__block__title">3 причины оформить полис ОСАГО:</h1>
                <div className="osago__columns">
                    <div className="osago__column">
                        <span className="osago__column__number">1</span>
                        <p className="osago__column__text">Это обязательно. Без полиса ОСАГО невозможно поставить автомобиль на учет в ГИБДД, также без него вы не имеете права управлять машиной - за это грозит штраф.</p>
                    </div>
                    <div className="osago__column">
                        <span className="osago__column__number">2</span>
                        <p className="osago__column__text">Это быстро. Оформление полиса не займет у вас много времени, а при встрече с сотрудником ДПС вы будете уверены, что ваши документы в порядке.</p>
                    </div>
                    <div className="osago__column">
                        <span className="osago__column__number">3</span>
                        <p className="osago__column__text">Это экономит деньги. Если вдруг вы оказались виновником ДТП, с полисом ОСАГО вам не придется платить из своего кармана, это сделает страховая компания. В любом другом случае финансовая компенсация возмещается за счет личных средств и в полном объеме.</p>
                    </div>
                </div>
            </section>

            <section className="osago__block--grey">
                <div className="osago__inner">
                    <h1 className="osago__block__title--black">При наступлении страхового случая полис ОСАГО гарантирует выплаты, если по вашей вине:</h1>
                    <div className="osago__columns mt-25">
                        <div className="osago__column--225">
                            <img className="osago__column__mark" src={ MarkGrey } alt="Ингуд | поврежден другой автомобиль" />
                            <p className="osago__column__text">поврежден другой автомобиль</p>
                        </div>
                        <div className="osago__column--225">
                            <img className="osago__column__mark" src={ MarkGrey } alt="Ингуд | причинен вред жизни или здоровью участникам ДТП - водителю и пассажирам - которые находились в другом автомобиле" />
                            <p className="osago__column__text">причинен вред жизни или здоровью участникам ДТП - водителю и пассажирам - которые находились в другом автомобиле</p>
                        </div>
                        <div className="osago__column--225">
                            <img className="osago__column__mark" src={ MarkGrey } alt="Ингуд | причинен вред жизни или здоровью пешехода" />
                            <p className="osago__column__text">причинен вред жизни или здоровью пешехода</p>
                        </div>
                        <div className="osago__column--225">
                            <img className="osago__column__mark" src={ MarkGrey } alt="Ингуд | нанесен ущерб любой другой собственности (забор, здание, светофор и т.д)" />
                            <p className="osago__column__text">нанесен ущерб любой другой собственности (забор, здание, светофор и т.д)</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content__block osago__block">
                <div className="osago__inner--59">
                    <div className="osago__calc">
                        <h1 className="osago__calc__title">Калькулятор ОСАГО</h1>
                    </div>
                    <p className="osago__calc__text">Для того, чтобы посчитать стоимость вашего полиса, вам нужно всего лишь 5 минут свободного времени и знание нескольких нюансов (мощность автомобиля, коэффициент бонус-малус, период страхования и проч.). Оставьте заявку, и мы подскажем вам итоговую стоимость.</p>
                    <div className="osago__columns">
                        <div className="osago__column--50">
                            <img src={ Car } alt="Ингуд | ОСАГО - Не нужен осмотр машины" />
                            <span>Не нужен осмотр машины</span>
                        </div>
                        <div className="osago__column--50">
                            <img src={ Support } alt="Ингуд | ОСАГО - Круглосуточная помощь в случае ДТП по телефону" />
                            <span>Круглосуточная помощь в случае ДТП<br/>по телефону</span>
                        </div>
                    </div>

                    <hr className="delimiter" />
                </div>

                <hr className="delimiter" />

                <div className="osago__calc--grey mt-25">
                    <h2 className="osago__calc__title--grey">Заполните информацию о легковом автомобиле</h2>
                </div>

                <div className="osago__calc__block">
                    <form className="osago__form">
                        <div className="osago__form__group one">
                            <label className="osago__form__group__label" htmlFor="host">Владелец ТС</label>
                            <Select id="host" options={ hostOptions } defaultValue={ hostOptions[0] } />
                        </div>
                        <div className="osago__form__group two">
                            <div className="flex">
                                <label className="osago__form__group__label" htmlFor="type">Тип ТС</label>
                                {/*<label className="osago__form__group__checkbox">*/}
                                {/*    <span>с прицепом</span>*/}
                                {/*    <input type="checkbox" />*/}
                                {/*    <span className="osago__form__group__checkmark" />*/}
                                {/*</label>*/}
                            </div>
                            <Select id="type" options={ typeOptions } defaultValue={ typeOptions[0] } />
                        </div>

                        <div className="osago__form__group five">
                            <label className="osago__form__group__label" htmlFor="power">Мощность двигателя, л.с.</label>
                            <Select id="power" options={ powerOptions } defaultValue={ powerOptions[0] } onChange={ (value) => setPower(value) } />
                        </div>

                        <div className="osago__form__group three">
                            <label className="osago__form__group__label" htmlFor="region">Регион</label>
                            <Select id="region" options={ regionOptions } defaultValue={ regionOptions[0] }  onChange={ (value) => setRegion(value) } />
                        </div>
                        <div className="osago__form__group four">
                            <label className="osago__form__group__label" htmlFor="drivers">Лица, допущенные к управлению</label>
                            <Select id="drivers" options={ driversOptions } defaultValue={ driversOptions[0] } onChange={ (value) => setDrivers(value) } />
                        </div>

                        <div className="osago__form__group six">
                            <label className="osago__form__group__label" htmlFor="period">Период пользования ТС</label>
                            <Select id="period" options={ periodOptions } defaultValue={ periodOptions[0] } onChange={ (value) => setPeriod(value) } />
                        </div>

                        <div className="osago__form__group eight">
                            <label className="osago__form__group__label" htmlFor="age">Возраст водителя</label>
                            <Select id="age" options={ ageOptions } defaultValue={ ageOptions[0] } onChange={ (value) => setAge(value) } isDisabled={ drivers.value === "unlimited" } />
                        </div>

                        <div className="osago__form__group nine">
                            <label className="osago__form__group__label" htmlFor="exp">Стаж водителя</label>
                            <Select id="exp" options={ expOptions } defaultValue={ expOptions[0] } onChange={ (value) => setExp(value) } isDisabled={ drivers.value === "unlimited" } />
                        </div>

                        <div className="osago__form__group seven">
                            <label className="osago__form__group__label" htmlFor="kbm">КБМ</label>
                            <Select id="kbm" options={ kbmOptions } defaultValue={ kbmOptions[0] } onChange={ (value) => setKbm(value) } isDisabled={ drivers.value === "unlimited" } />
                        </div>
                    </form>
                </div>

                <div className="puteshestviya__block puteshestviya__block--center">
                    <button className="button button--red mauto" onClick={ calc }>Рассчитать</button>
                </div>

                <h1 ref={ res } className="calc__result mauto" />
                <div ref={ form } className="puteshestviya__block puteshestviya__block--center hidden">
                    <Feedback className="button button--red mauto" page="ОСАГО калькулятор" calc={ true } data={ data } />
                </div>
            </section>

        </main>
    )

}

const priceRange = {
    min: 4134,
    max: 5436
}

const hostOptions = [
    { value: 'individual', label: 'Физ. лицо' },
    { value: 'entity', label: 'Юр. лицо' }
]

const typeOptions = [
    { value: 'b', label: 'B - Легковые' },
    { value: 'a', label: 'A - Мотоциклы' }
]

const driversOptions = [
    { value: 'unlimited', label: 'Неограниченное кол-во лиц' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' }
]

const periodOptions = [
    { value: '1', label: '1 год' },
    { value: '0.7', label: '6 мес' },
    { value: '0.5', label: '3 мес' }
]

const powerOptions = [
    { value: '1', label: 'До 70 л.с.' },
    { value: '1.1', label: 'от 71 до 100 л.с.' },
    { value: '1.2', label: 'от 101 до 120 л.с.' },
    { value: '1.4', label: 'от 121 до 150 л.с.' },
    { value: '1.6', label: 'от 151 и выше л.с.' }
]

const kbmOptions = [
    { value: 'dontKnow', label: 'Я не знаю свой КБМ' },
    { value: '2.45', label: 'M (КБМ = 2.45)' },
    { value: '2.3', label: 'Класс 0 (КБМ = 2.3)' },
    { value: '1.55', label: 'Класс 1 (КБМ = 1.55)' },
    { value: '1.4', label: 'Класс 2 (КБМ = 1.4)' },
    { value: '1', label: 'Класс 3 (КБМ = 1)' },
    { value: '0.95', label: 'Класс 4 (КБМ = 0.95)' },
    { value: '0.9', label: 'Класс 5 (КБМ = 0.9)' },
    { value: '0.85', label: 'Класс 6 (КБМ = 0.85)' },
    { value: '0.8', label: 'Класс 7 (КБМ = 0.8)' },
    { value: '0.75', label: 'Класс 8 (КБМ = 0.75)' },
    { value: '0.7', label: 'Класс 9 (КБМ = 0.7)' },
    { value: '0.65', label: 'Класс 10 (КБМ = 0.65)' },
    { value: '0.6', label: 'Класс 11 (КБМ = 0.6)' },
    { value: '0.55', label: 'Класс 12 (КБМ = 0.55)' },
    { value: '0.5', label: 'Класс 13 (КБМ = 0.5)' }
]

const regionOptions = [
    { value: '1.72', label: 'Красноярск' },
    { value: '1.31', label: 'Железногорск' },
    { value: '1.31', label: 'Норильск' },
    { value: '1.2', label: 'Ачинск' },
    { value: '1.2', label: 'Зеленогорск' },
    { value: '1.1', label: 'Канск' },
    { value: '1.1', label: 'Лесосибирск' },
    { value: '1.1', label: 'Минусинск' },
    { value: '1.1', label: 'Назарово' },
    { value: '0.91', label: 'Прочие города и населенные пункты' }
]

const ageOptions = [
    { value: '16-21', label: '16-21' },
    { value: '22-24', label: '22-24' },
    { value: '25-29', label: '25-29' },
    { value: '30-34', label: '30-34' },
    { value: '35-39', label: '35-39' },
    { value: '40-49', label: '40-49' },
    { value: '50-59', label: '50-59' },
    { value: '59', label: 'Старше 59' },
]

const expOptions = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3-4', label: '3-4' },
    { value: '5-6', label: '5-6' },
    { value: '7-9', label: '7-9' },
    { value: '10-14', label: '10-14' },
    { value: '14', label: 'Более 14' },
]

const ageExpOptions = [
    { value: '1.93', age: '16-21', exp: '0' },
    { value: '1.9', age: '16-21', exp: '1' },
    { value: '1.87', age: '16-21', exp: '2' },
    { value: '1.66', age: '16-21', exp: '3-4' },
    { value: '1.64', age: '16-21', exp: '5-6' },

    { value: '1.79', age: '22-24', exp: '0' },
    { value: '1.77', age: '22-24', exp: '1' },
    { value: '1.76', age: '22-24', exp: '2' },
    { value: '1.08', age: '22-24', exp: '3-4' },
    { value: '1.06', age: '22-24', exp: '5-6' },
    { value: '1.06', age: '22-24', exp: '7-9' },

    { value: '1.77', age: '25-29', exp: '0' },
    { value: '1.68', age: '25-29', exp: '1' },
    { value: '1.61', age: '25-29', exp: '2' },
    { value: '1.06', age: '25-29', exp: '3-4' },
    { value: '1.05', age: '25-29', exp: '5-6' },
    { value: '1.05', age: '25-29', exp: '7-9' },
    { value: '1.01', age: '25-29', exp: '10-14' },

    { value: '1.62', age: '30-34', exp: '0' },
    { value: '1.61', age: '30-34', exp: '1' },
    { value: '1.59', age: '30-34', exp: '2' },
    { value: '1.04', age: '30-34', exp: '3-4' },
    { value: '1.04', age: '30-34', exp: '5-6' },
    { value: '1.01', age: '30-34', exp: '7-9' },
    { value: '0.96', age: '30-34', exp: '10-14' },
    { value: '0.95', age: '30-34', exp: '14' },

    { value: '1.61', age: '35-39', exp: '0' },
    { value: '1.59', age: '35-39', exp: '1' },
    { value: '1.58', age: '35-39', exp: '2' },
    { value: '0.99', age: '35-39', exp: '3-4' },
    { value: '0.96', age: '35-39', exp: '5-6' },
    { value: '0.95', age: '35-39', exp: '7-9' },
    { value: '0.95', age: '35-39', exp: '10-14' },
    { value: '0.94', age: '35-39', exp: '14' },

    { value: '1.59', age: '40-49', exp: '0' },
    { value: '1.58', age: '40-49', exp: '1' },
    { value: '1.57', age: '40-49', exp: '2' },
    { value: '0.95', age: '40-49', exp: '3-4' },
    { value: '0.95', age: '40-49', exp: '5-6' },
    { value: '0.94', age: '40-49', exp: '7-9' },
    { value: '0.94', age: '40-49', exp: '10-14' },
    { value: '0.94', age: '40-49', exp: '14' },

    { value: '1.58', age: '50-59', exp: '0' },
    { value: '1.57', age: '50-59', exp: '1' },
    { value: '1.56', age: '50-59', exp: '2' },
    { value: '0.94', age: '50-59', exp: '3-4' },
    { value: '0.94', age: '50-59', exp: '5-6' },
    { value: '0.94', age: '50-59', exp: '7-9' },
    { value: '0.94', age: '50-59', exp: '10-14' },
    { value: '0.93', age: '50-59', exp: '14' },

    { value: '1.55', age: '59', exp: '0' },
    { value: '1.54', age: '59', exp: '1' },
    { value: '1.53', age: '59', exp: '2' },
    { value: '0.92', age: '59', exp: '3-4' },
    { value: '0.91', age: '59', exp: '5-6' },
    { value: '0.91', age: '59', exp: '7-9' },
    { value: '0.91', age: '59', exp: '10-14' },
    { value: '0.90', age: '59', exp: '14' }
]