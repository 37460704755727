import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import links from '../../links'
import './Header.css'
import 'hamburgers/dist/hamburgers.min.css'

import Logo from '../../assets/logo.png'
import Phone from '../../assets/phone.png'

export default () => {

    const [ isOpen, setIsOpen ] = useState(false)

    const mobileMenu = useRef(null)

    useEffect(() => {
        if(mobileMenu)
            mobileMenu.current.classList.toggle("header__navigation--mobile-open")
    }, [ isOpen ])

    return (
        <header className="header">
            <div className="header__inner">
                <Link to="/" className="header__logo__wrapper">
                    <img src={ Logo } className="header__logo" alt="Ингуд - страхование ОСАГО и КАСКО" />
                </Link>

                <nav role="navigation" aria-label="Стрховые полюсы" className="header__navigation">
                    {
                        links.slice(0, 5).map(({ name, link }) => (
                            <Link key={ `header_${ link }` } to={ `/strahovanie/${ link }` } className="header__navigation__link">
                                { name }
                            </Link>
                        ))
                    }
                    <Link to="/to" className="header__navigation__link">ТехОсмотр</Link>
                    <Link to="/blog" className="header__navigation__link">Блог</Link>
                </nav>

                <div className="header__phone">
                    <a href="tel:83912688888">
                        <img src={ Phone } className="header__phone__icon" alt="ОСАГО и КАСКО - телефон для справок" />
                    </a>
                    <a href="tel:83912688888" className="header__phone__number">268-88-88</a>

                    <button
                        onClick={ () => setIsOpen(!isOpen) }
                        className={ `hamburger hamburger--collapse${ isOpen ? ' is-active' : '' }` }
                        type="button"
                        aria-label="Меню"
                        aria-controls="navigation">
                        <span className="hamburger-box">
                            <span className="hamburger-inner" />
                        </span>
                    </button>
                </div>
            </div>
            <div className="header__inner">
                <nav ref={ mobileMenu } role="navigation" aria-label="Стрховые полюсы" className="header__navigation--mobile">
                    {
                        links.slice(0, 5).map(({ name, link }) => (
                            <Link
                                key={ `header_mobile_${ link }` }
                                to={ `/strahovanie/${ link }` }
                                onClick={ () => setIsOpen(!isOpen) }
                                className="header__navigation__link--mobile">
                                { name }
                            </Link>
                        ))
                    }
                    <Link to="/to" className="header__navigation__link--mobile">ТехОсмотр</Link>
                    <Link to="/blog" onClick={ () => setIsOpen(!isOpen) } className="header__navigation__link--mobile">
                        Блог
                    </Link>
                </nav>
            </div>
        </header>
    )

}
