import React from 'react'
import { Helmet } from 'react-helmet'
import { findByLink } from '../../../store'

import './Post.css'

import Cars from '../../../assets/blog/cars.png'

import _1_1 from './assets/1.png'
import _1_2 from './assets/2.png'
import _1_3 from './assets/3.png'
import _1_4 from './assets/4.png'
import _1_5 from './assets/5.png'
import _1_6 from './assets/6.png'
import _1_7 from './assets/7.png'
import _1_8 from './assets/8.png'
import _1_9 from './assets/9.png'
import _1_10 from './assets/10.png'
import _1_11 from './assets/11.png'
import _1_12 from './assets/12.png'
import _1_13 from './assets/13.png'
import _1_14 from './assets/14.png'

export default ({ match: { params: { link } } }) => {

    const post = findByLink(link)

    return (
        <main className="content post">

            <Helmet>
                <title>{ post.title } | Страховой брокер «Ингуд»</title>
                <meta name="description" content={ post.description } />
            </Helmet>

            <section className="post__content">

                <div className="post__header">
                    <img src={ Cars } className="post__header__image" alt="Схема заявления убытка на месте ДТП по Европротоколу" />
                    <div>
                        <h1>Схема заявления убытка на месте ДТП по Европротоколу</h1>
                        <h3>Европротокол - это процедура оформления документов о ДТП без участия сотрудников полиции.</h3>
                    </div>
                </div>

                <h2 className="post__title">Европротокол можно оформить если:</h2>
                <div className="post__row">
                    <figure className="post__row__item">
                        <img src={ _1_1 } alt="Нет разногласий" />
                        <figcaption>Нет разногласий</figcaption>
                        <p>Один из участников ДТП обязан сделать отметку о том, что он признает свою вину</p>
                    </figure>
                    <figure className="post__row__item">
                        <img src={ _1_2 } alt="Нет пострадавших" />
                        <figcaption>Нет пострадавших</figcaption>
                        <p>Один из участников ДТП обязан сделать отметку о том, что он признает свою вину</p>
                    </figure>
                    <figure className="post__row__item">
                        <img src={ _1_3 } alt="Два участника ДТП" />
                        <figcaption>Два участника ДТП</figcaption>
                        <p>Один из участников ДТП обязан сделать отметку о том, что он признает свою вину</p>
                    </figure>
                    <figure className="post__row__item">
                        <img src={ _1_4 } alt="Есть ОСАГО" />
                        <figcaption>Есть ОСАГО</figcaption>
                        <p>Один из участников ДТП обязан сделать отметку о том, что он признает свою вину</p>
                    </figure>
                </div>

                <div className="post__row__splash">
                    <p>Если хотя бы одно из условий не соблюдено, то нужно вызвать ГИБДД</p>
                </div>

                <div className="post__row--column">
                    <div className="row__header">
                        <span className="puteshestviya__column__number">1</span>
                        <h2>Заполнение извещения о ДТП</h2>
                    </div>
                    <div className="row__grid">
                        <figure className="post__grid__item">
                            <img src={ _1_5 } alt="Пользуйтесь только шариковой ручкой" />
                            <figcaption>Пользуйтесь только шариковой ручкой</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_6 } alt="Каждый водитель заполняет свою колонку" />
                            <figcaption>Каждый водитель заполняет свою колонку</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_7 } alt="Когда оба экземпляра заполнены с лицевой стороны - разделите их" />
                            <figcaption>Когда оба экземпляра заполнены с лицевой стороны - разделите их</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_8 } alt="Обратную сторону каждый участник заполняет сам" />
                            <figcaption>Обратную сторону каждый участник заполняет сам</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_9 } alt="Оба водителя ставят по две пары подписей на лицевой извещения - под информацией и согласием" />
                            <figcaption>Оба водителя ставят по две пары подписей на лицевой извещения - под информацией и "согласием"</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_10 } alt="После разъединения в бланки можно вносить исправления с заверением их подписями водителей" />
                            <figcaption>После разъединения в бланки можно вносить исправления с заверением их подписями водителей</figcaption>
                        </figure>
                    </div>
                </div>

                <div className="post__row--column">
                    <div className="row__header">
                        <span className="puteshestviya__column__number">2</span>
                        <h2>Оформление схемы ДТП</h2>
                    </div>
                    <div className="post__row--img">
                        <img src={ _1_11 } className="post__row__img" alt="Оформление схемы ДТП" />
                    </div>
                </div>

                <div className="post__row--column">
                    <div className="row__header">
                        <span className="puteshestviya__column__number">3</span>
                        <h2>После оформления ДТП</h2>
                    </div>
                    <div className="row__grid">
                        <figure className="post__grid__item">
                            <img src={ _1_12 } alt="У Вас есть 5 дней, чтобы отправить оформленное извещение своему страховщику" />
                            <figcaption>У Вас есть 5 дней, чтобы отправить оформленное извещение своему страховщику</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_13 } alt="Если виновник не вы, то приложите к экземпляру извещения требования о страховом возмещении, если вы на него претендуете" />
                            <figcaption>Если виновник не вы, то приложите к экземпляру извещения требования о страховом возмещении, если вы на него претендуете</figcaption>
                        </figure>
                        <figure className="post__grid__item">
                            <img src={ _1_14 } alt="Нельзя приступать к ремонту ТС в течении 15 дней после ДТП, либо без письменного согласия страховщика" />
                            <figcaption>Нельзя приступать к ремонту ТС в течении 15 дней после ДТП, либо без письменного согласия страховщика</figcaption>
                        </figure>
                    </div>
                </div>

            </section>

        </main>
    )
}