import React from 'react'

export default () => (
    <main className="content">
        <section className="osago__block">
            <div className="osago__inner--59">
                <h2 className="puteshestviya__title--blue">Вопросы и ответы</h2>
                <div className="puteshestviya__block">
                    <h3 className="puteshestviya__block__title">Что такое страховка туриста?</h3>
                    <p className="puteshestviya__block__text">Страховка туриста – договор страхования, заключенный между страхователем и страховой компанией. Последняя берет на себя обязательства своевременно оказать помощь застрахованному лицу и покрыть расходы, которые возникли при неблагоприятно сложившихся обстоятельствах. Полис позволит владельцу почувствовать финансовую защиту, если понадобится срочная медицинская помощь или лечение.</p>
                    <hr className="delimiter--puteshestviya" />
                </div>

                <div className="puteshestviya__block">
                    <h3 className="puteshestviya__block__title">Зачем нужна страховка при путешествии по России?</h3>
                    <p className="puteshestviya__block__text">Ошибочно думать, что полис необходим только для выезжающих за рубеж. Путешествие или отдых в России также могут быть связаны с неблагоприятными обстоятельствами, способными привести к страховому случаю. Полис страхования для путешествующих по РФ покрывает следующие риски: медицинская транспортировка и расходы, транспортные расходы, срочные сообщения и репатриация.</p>
                    <hr className="delimiter--puteshestviya" />
                </div>

                <div className="puteshestviya__block">
                    <h3 className="puteshestviya__block__title">Что делать, если у меня нет с собой оформленной страховки в момент страхового случая?</h3>
                    <p className="puteshestviya__block__text">Пребывая за границей, не всегда удобно носить с собой оформленный полис ВЗР. Потому вполне возможно, что при наступлении страхового случая он не окажется при себе. Это не проблема, если заранее записать, сфотографировать, сохранить в мобильном устройстве номер полиса с контактами сервисного центра, ведь телефон всегда под рукой.</p>
                    <hr className="delimiter--puteshestviya" />
                </div>
            </div>
        </section>
    </main>
)